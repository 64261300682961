@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Poppins:wght@300;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #eee;
}

$light-color: white;
$medium-color: grey;
$dark-color: black;
$primary-color: #52b3de;
$secondary-color: #c9c8c8;
$navbarTextColor: #0a40fe;
$hoverColor: #333;

$sectionWidth: calc(100% - 4rem);

$sliderHeight: calc(100vh - 130px);

$shadow: $primary-color 4px 4px;
$newsImgShadow: 10px 20px 15px #333;

// Link non evidenziati al click
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#prossima-partita,
section + section:not(#classifica, #link-videos) {
  margin-top: 40px !important;
}

section {
  width: $sectionWidth;
  margin: 0 auto;
}

.page--container > section {
  width: unset;
}

button {
  font-family: "Neo Sans Pro", sans-serif;
  background-color: $primary-color;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  color: $dark-color;
  transition: all 0.3s ease;
  cursor: pointer;

  a {
    padding: 5px 15px;
    font-weight: bold;
  }

  &:hover {
    background-color: $medium-color;
  }
}

a {
  text-decoration: none;
  color: inherit;
  letter-spacing: 0.05em;
}

ul {
  list-style: none;
  padding: 0;
}

h1 {
  color: $primary-color;
  font-size: 2rem;
  font-weight: bolder;
  text-shadow: 1px 1px 2px black;
  text-align: center;
  margin: 20px 10px 30px;
}

h3 {
  text-transform: uppercase;
  font-family: "Varela Round", sans-serif;
  font-size: 1rem;
  text-align: left;
  margin: 0;
  margin-left: 15px;
}

h4 {
  font-size: 1.2rem;
}

.buttons--container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin: 0 auto;
}

// btn torna indietro
.return--link {
  margin: 0 auto;
  max-width: fit-content;
  padding: 10px 20px;
  background-color: $primary-color;
  border-radius: 30px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid $primary-color;

  a {
    font-weight: bold;
  }

  &:hover {
    background-color: $light-color;
  }
}

// btn mostra tutto news e video
.bar {
  font-weight: 100;
  font-family: "Neo Sans Pro", sans-serif;
  color: $medium-color;
  vertical-align: top;
  text-shadow: none;
  @media (max-width: 413px) {
    display: none;
  }
}

.btn--plus {
  color: $medium-color;
  font-family: "Neo Sans Pro", sans-serif;
  font-size: 0.8rem;
  font-weight: lighter;
  text-shadow: none;
  vertical-align: middle;
  @media (max-width: 413px) {
    display: block;
  }
}

.section {
  transition: all 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(2rem);
}

#coppa-veneto {
  margin-top: 30px;
  padding-bottom: 0;
}

.squadre {
  .arrow--down,
  .arrow--up {
    transition: all 0.5s ease-in-out;
  }
}

.ul--squadre {
  max-height: 0;
  overflow: hidden;
  font-size: 1rem;
  color: $light-color;
  transition: all 0.5s ease-in-out;

  li {
    padding: 10px;
  }
}

.overlay {
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -1;
}

.hidden {
  opacity: 0;
}

.display--none {
  transition: all 0.5s ease-in-out;
  display: none;
}

// .category--title {
//   margin: 0;
//   margin-left: 15px;
//   text-align: center;
//   font-size: 3rem;
// }

iframe {
  width: 97%;
}

.iframe--classifica--prima-squadra {
  height: 680px;
}

.iframe--classifica--coppa {
  height: 4800px;
}

.iframe--classifica--juniores {
  height: 500px;
}

.iframe--classifica--allievi {
  height: 650px;
}

.iframe--classifica--giovanissimi {
  height: 680px;
}

.iframe--classifica--giovanissimi_2 {
  height: 460px;
}

.iframe--classifica--esordienti {
  height: 380px;
}

.iframe--marcatori {
  height: 700px;
}

.iframe--marcatori-coppa {
  height: 700px;
}

.iframe--risultati {
  height: 520px;
}

.iframe--risultati-coppa {
  height: 400px;
}

.iframe--prossima-partita {
  height: 300px;
}

.page--container {
  width: $sectionWidth;
  margin: 60px auto;

  .single--container {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    img {
      width: 100%;
      box-shadow: $newsImgShadow;
    }

    .single--news--content {
      .single--news--text {
        text-align: left;

        .single--news--date {
          color: $medium-color;
          font-size: 0.8rem;
        }
      }

      .single--news--article {
        line-height: 160%;
      }
    }
  }
}

#storia {
  justify-content: center;
  text-align: center;
  width: $sectionWidth;
  margin: 0 auto;

  img {
    width: 100%;
    padding-bottom: 30px;
  }

  p {
    text-align: left;
    line-height: 2;
    padding: 0 12px;
  }
}

.squadre--container {
  // text-align: center;
  margin: auto;

  img {
    width: 100%;
    margin: 20px 0;
  }
}

#gallery {
  justify-content: center;
  text-align: center;
  align-items: center;

  .gallery--container {
    // display: grid;
    // padding: 5px;
    flex-direction: column;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 10px 5px;
    // align-items: baseline;
    .photo {
      text-align: center;
      padding: 5px 0;

      p {
        margin: 10px;
        font-size: 1.3rem;
      }

      img {
        width: 100%;
      }
    }
  }

  .btn--gallery {
    margin-top: 30px;
  }
}

.single--photo--container {
  text-align: center;

  img {
    width: 100%;
  }
}

#contatti {
  align-items: center;
  justify-content: center;

  .ul--contatti {
    padding: 20px;
    display: inline;

    .contatti--span {
      font-weight: bolder;
      display: block;
      padding: 5px 0;
    }

    li {
      padding: 10px;
    }
  }
}

#map {
  width: 100%;
  text-align: center;

  .googleMapsIframe {
    width: 90%;
    height: 600px;
  }
}

//// Splide
.splide__pagination {
  bottom: 0 !important;
}

.splide__pagination__page {
  &.is-active {
    background-color: #52b3de !important;
  }
}

.splide__arrow--prev {
  left: -1em !important;
}

.splide__arrow--next {
  right: -1em !important;
}

.splide__arrow {
  background: none !important;
  height: 3em !important;
  width: 3em !important;
  top: 35% !important;

  svg {
    fill: $primary-color !important;
    height: 3em !important;
    width: 3em !important;
  }
}

@mixin ul_animation($bg: false, $bottom: false, $width: false) {
  a {
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      position: absolute;
      transition: width 0.5s ease-in-out;
      @if ($bg) {
        background: $bg;
      } @else {
        background: $dark-color;
      }
      @if ($bottom) {
        bottom: $bottom;
      } @else {
        bottom: -8px;
      }
    }

    &:hover {
      &:after {
        @if ($width) {
          width: $width;
        } @else {
          width: 100%;
        }
      }
    }
  }
}

@mixin transition() {
  transition: all 0.3s ease-in-out;
}

////// Manutenzione sito //////////
.maintain_logo {
  width: 200px;
  margin: 30px auto;

  img {
    width: 100%;
  }
}
