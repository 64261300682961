@import "utilityes";

#navbar {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 30px;
  height: 130px;
  text-align: center;
  background: linear-gradient($light-color 50%, $primary-color);
  box-shadow: 0 0 10px #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.7s;

  &.scrolled-nav {
    height: 90px;
  }

  .logo {
    width: 50px;
    height: 80px;
    background: url("../Img/logo.png");
    background: {
      attachment: scroll;
      size: contain;
      repeat: no-repeat;
      position: center;
    }
  }

  .menu--inline {
    margin: auto;
    display: none;
    font-size: 1.3rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $dark-color;

    .squadre--inline {
      position: relative;
      display: flex;
      flex-direction: column;

      .submenu {
        background-color: $light-color;
        position: absolute;
        text-align: left;
        max-height: 0;
        overflow: hidden;
        top: 100%;
        left: 50%;
        min-width: 200px;
        transform: translateY(5%) translateX(-50%);
        transition: 0.3s all ease;
        box-shadow: 0 0 5px $medium-color;

        &:hover {
          display: block;
        }
      }

      &:hover {
        cursor: pointer;

        .ul--squadre--inline {
          display: block;
        }
      }

      // Lista sottomenu
      .sub--li {
        font-size: 1rem;
        padding: 10px 20px;
        transition: 0.3s all ease;

        &.rosa {
          color: $medium-color;
        }

        &:hover {
          background-color: $primary-color;
          color: $light-color;
        }
      }
    }

    // Lista menu
    .main--li {
      transition: 0.3s all ease;
      font-family: "Varela Round", sans-serif;
      margin: 0 2%;
      white-space: nowrap;

      &.has--submenu {
        &:hover {
          > .submenu {
            max-height: 1000px;
          }
        }
      }
    }
  }
}

#header {
  .slider {
    color: $light-color;
    position: relative;
    max-width: 100vw;
    height: calc($sliderHeight - 100px);
    display: flex;
    overflow: hidden;

    .logo {
      width: 90px;

      img {
        width: 100%;
      }
    }

    .slide--text {
      margin: 30px 0 0;
      font-family: "Neo Sans Pro", sans-serif;
      text-transform: uppercase;
      transition: all 1s;
      text-align: center;
    }

    .slide--storia {
      padding: 0;
      margin: 20px;
    }

    .slide {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 2.5rem;
        color: $light-color;
      }

      .btn--storia {
        margin-bottom: 20px;
      }

      .bg1 {
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        background: url(../Img/tribune_originale.jpg) no-repeat center/cover;
      }

      .bg2 {
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        background: url(../Img/gabatel-marcolin-bg.png) no-repeat center/cover;
      }
    }

    .dots {
      z-index: 2;
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
    }

    .dots__dot {
      border: none;
      background-color: transparent;
      color: $secondary-color;
      opacity: 0.7;
      padding: 5px;
      cursor: pointer;
      transition: all 0.5s;

      /* Only necessary when overlying images */
      // box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7);
    }

    .dots__dot:last-child {
      margin: 0;
    }

    .dots__dot--active {
      color: #fff;
      opacity: 1;
    }
  }
}

#navbar-2 {
  background-color: $primary-color;
  height: 100px;

  .icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    img {
      width: 30px;
    }

    .icon {
      width: 25%;
    }

    .icon--text {
      font-size: 0.7rem;
      margin: 0;
      padding: 3px;
    }
  }
}

main {
  margin-top: 40px;
}

#classifica {
  margin: auto;
  // width: 100%;
  // height: 100vh;
}

#news,
.news {
  max-width: 1400px;
  margin: 0 auto;

  .news--h1 {
    grid-column: span 3;
    padding-top: 0;
  }

  .filter-button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;

    .filter-button {
      width: 100%;

      &.active {
        background-color: $light-color;
        border: 2px solid $primary-color;
      }
    }
  }

  .news--container {
    a {
      margin: 0 auto;
    }
  }

  .news--content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 5/7;
      box-shadow: $newsImgShadow;
    }

    .news--text {
      background-color: $light-color;
      box-shadow: $shadow;
      position: relative;
      top: -50px;
      padding: 10px 20px 0;
      margin: 0 20px;
      flex-grow: 1;
      width: 75%;
      text-align: left;

      .news--date {
        color: $medium-color;
        font-size: 0.8rem;
      }
    }
  }

  .splide__slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 4%;
    transition: all 0.3s ease;

    &:hover {
      a img {
        filter: none;
      }

      .news--text {
        background-color: $hoverColor;
        @include transition();

        .news--date,
        .news--title {
          color: $light-color;
        }
      }
    }

    a {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      img {
        filter: saturate(70%);
      }
    }
  }
}

#videos,
.videos {
  justify-content: center;
  text-align: center;

  .video--container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;

    h1 {
      grid-column: span 3;
    }

    .video--content {
      margin: auto;
      display: flex;
      flex-direction: column;

      video {
        margin: auto;
        width: 100%;
        aspect-ratio: 16/12;
      }

      .video--text {
        background-color: $light-color;
        box-shadow: $shadow;
        flex-grow: 1;
        line-height: 30px;
      }

      .video--date {
        color: $medium-color;
        font-size: 0.8rem;
        margin: 10px 10px;
        text-align: left;
      }

      .video--title {
        margin: 20px 10px;
        padding-bottom: 20px;
        text-align: left;
      }
    }
  }
}

// Sponsor
#sponsor {
  width: $sectionWidth;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 5%;
  margin: 0 auto;

  .main-sponsor {
    padding: 10px 0 20px;
    width: 100%;
    border-bottom: 3px solid $primary-color;

    h1 {
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .official--title {
    padding: 10px;
  }

  .other-sponsor {
    width: 100%;
    margin: 0 auto 20px;
    display: grid;
    padding: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 3px solid $primary-color;

    h1 {
      grid-column: span 3;
    }

    .sponsor-img {
      margin: 10px;

      img {
        width: 100%;
      }
    }
  }
}

#footer {
  background: linear-gradient($light-color 10%, $primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  a {
    transition: 0.3s all ease;

    &:hover {
      scale: 1.2;
      transform-origin: center;
    }
  }

  .social {
    width: 82px;
    padding: 10px;
    color: $dark-color;
    display: flex;
    justify-content: space-between;
  }

  .privacy-policy {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.6rem;

    .policy-title {
      margin: 0 10px;
    }
  }

  .preferenze-cookie {
    margin: 10px;
    font-size: 0.6rem;
  }

  .last--row {
    .developer {
      i {
        padding: 0 0.5rem;
      }
    }

    text-align: center;
    font-size: 0.8rem;
  }
}

@import "media_queries";
