@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Poppins:wght@300;700&display=swap";
body {
  background-color: #eee;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

* {
  -webkit-tap-highlight-color: #0000;
}

#prossima-partita, section + section:not(#classifica, #link-videos) {
  margin-top: 40px !important;
}

section {
  width: calc(100% - 4rem);
  margin: 0 auto;
}

.page--container > section {
  width: unset;
}

button {
  color: #000;
  cursor: pointer;
  background-color: #52b3de;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-family: Neo Sans Pro, sans-serif;
  font-size: 1rem;
  transition: all .3s;
}

button a {
  padding: 5px 15px;
  font-weight: bold;
}

button:hover {
  background-color: gray;
}

a {
  color: inherit;
  letter-spacing: .05em;
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
}

h1 {
  color: #52b3de;
  text-shadow: 1px 1px 2px #000;
  text-align: center;
  margin: 20px 10px 30px;
  font-size: 2rem;
  font-weight: bolder;
}

h3 {
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 0 15px;
  font-family: Varela Round, sans-serif;
  font-size: 1rem;
}

h4 {
  font-size: 1.2rem;
}

.buttons--container {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin: 0 auto;
  display: flex;
}

.return--link {
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
  background-color: #52b3de;
  border: 2px solid #52b3de;
  border-radius: 30px;
  margin: 0 auto;
  padding: 10px 20px;
  transition: all .3s;
}

.return--link a {
  font-weight: bold;
}

.return--link:hover {
  background-color: #fff;
}

.bar {
  color: gray;
  vertical-align: top;
  text-shadow: none;
  font-family: Neo Sans Pro, sans-serif;
  font-weight: 100;
}

@media (max-width: 413px) {
  .bar {
    display: none;
  }
}

.btn--plus {
  color: gray;
  text-shadow: none;
  vertical-align: middle;
  font-family: Neo Sans Pro, sans-serif;
  font-size: .8rem;
  font-weight: lighter;
}

@media (max-width: 413px) {
  .btn--plus {
    display: block;
  }
}

.section {
  transition: all 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(2rem);
}

#coppa-veneto {
  margin-top: 30px;
  padding-bottom: 0;
}

.squadre .arrow--down, .squadre .arrow--up {
  transition: all .5s ease-in-out;
}

.ul--squadre {
  max-height: 0;
  color: #fff;
  font-size: 1rem;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.ul--squadre li {
  padding: 10px;
}

.overlay {
  opacity: .5;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  position: absolute;
}

.hidden {
  opacity: 0;
}

.display--none {
  transition: all .5s ease-in-out;
  display: none;
}

iframe {
  width: 97%;
}

.iframe--classifica--prima-squadra {
  height: 680px;
}

.iframe--classifica--coppa {
  height: 4800px;
}

.iframe--classifica--juniores {
  height: 500px;
}

.iframe--classifica--allievi {
  height: 650px;
}

.iframe--classifica--giovanissimi {
  height: 680px;
}

.iframe--classifica--giovanissimi_2 {
  height: 460px;
}

.iframe--classifica--esordienti {
  height: 380px;
}

.iframe--marcatori, .iframe--marcatori-coppa {
  height: 700px;
}

.iframe--risultati {
  height: 520px;
}

.iframe--risultati-coppa {
  height: 400px;
}

.iframe--prossima-partita {
  height: 300px;
}

.page--container {
  width: calc(100% - 4rem);
  margin: 60px auto;
}

.page--container .single--container {
  width: 100%;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
}

.page--container .single--container img {
  width: 100%;
  box-shadow: 10px 20px 15px #333;
}

.page--container .single--container .single--news--content .single--news--text {
  text-align: left;
}

.page--container .single--container .single--news--content .single--news--text .single--news--date {
  color: gray;
  font-size: .8rem;
}

.page--container .single--container .single--news--content .single--news--article {
  line-height: 160%;
}

#storia {
  text-align: center;
  width: calc(100% - 4rem);
  justify-content: center;
  margin: 0 auto;
}

#storia img {
  width: 100%;
  padding-bottom: 30px;
}

#storia p {
  text-align: left;
  padding: 0 12px;
  line-height: 2;
}

.squadre--container {
  margin: auto;
}

.squadre--container img {
  width: 100%;
  margin: 20px 0;
}

#gallery {
  text-align: center;
  justify-content: center;
  align-items: center;
}

#gallery .gallery--container {
  flex-direction: column;
}

#gallery .gallery--container .photo {
  text-align: center;
  padding: 5px 0;
}

#gallery .gallery--container .photo p {
  margin: 10px;
  font-size: 1.3rem;
}

#gallery .gallery--container .photo img {
  width: 100%;
}

#gallery .btn--gallery {
  margin-top: 30px;
}

.single--photo--container {
  text-align: center;
}

.single--photo--container img {
  width: 100%;
}

#contatti {
  justify-content: center;
  align-items: center;
}

#contatti .ul--contatti {
  padding: 20px;
  display: inline;
}

#contatti .ul--contatti .contatti--span {
  padding: 5px 0;
  font-weight: bolder;
  display: block;
}

#contatti .ul--contatti li {
  padding: 10px;
}

#map {
  width: 100%;
  text-align: center;
}

#map .googleMapsIframe {
  width: 90%;
  height: 600px;
}

.splide__pagination {
  bottom: 0 !important;
}

.splide__pagination__page.is-active {
  background-color: #52b3de !important;
}

.splide__arrow--prev {
  left: -1em !important;
}

.splide__arrow--next {
  right: -1em !important;
}

.splide__arrow {
  height: 3em !important;
  width: 3em !important;
  background: none !important;
  top: 35% !important;
}

.splide__arrow svg {
  fill: #52b3de !important;
  height: 3em !important;
  width: 3em !important;
}

.maintain_logo {
  width: 200px;
  margin: 30px auto;
}

.maintain_logo img {
  width: 100%;
}

#navbar {
  z-index: 10;
  height: 130px;
  text-align: center;
  background: linear-gradient(#fff 50%, #52b3de);
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  transition: all .7s;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 0 10px #f4f4f4;
}

#navbar.scrolled-nav {
  height: 90px;
}

#navbar .logo {
  width: 50px;
  height: 80px;
  background: url("logo.c669552a.png") center / contain no-repeat;
}

#navbar .menu--inline {
  text-align: center;
  color: #000;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 1.3rem;
  display: none;
}

#navbar .menu--inline .squadre--inline {
  flex-direction: column;
  display: flex;
  position: relative;
}

#navbar .menu--inline .squadre--inline .submenu {
  text-align: left;
  max-height: 0;
  min-width: 200px;
  background-color: #fff;
  transition: all .3s;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  transform: translateY(5%)translateX(-50%);
  box-shadow: 0 0 5px gray;
}

#navbar .menu--inline .squadre--inline .submenu:hover {
  display: block;
}

#navbar .menu--inline .squadre--inline:hover {
  cursor: pointer;
}

#navbar .menu--inline .squadre--inline:hover .ul--squadre--inline {
  display: block;
}

#navbar .menu--inline .squadre--inline .sub--li {
  padding: 10px 20px;
  font-size: 1rem;
  transition: all .3s;
}

#navbar .menu--inline .squadre--inline .sub--li.rosa {
  color: gray;
}

#navbar .menu--inline .squadre--inline .sub--li:hover {
  color: #fff;
  background-color: #52b3de;
}

#navbar .menu--inline .main--li {
  white-space: nowrap;
  margin: 0 2%;
  font-family: Varela Round, sans-serif;
  transition: all .3s;
}

#navbar .menu--inline .main--li.has--submenu:hover > .submenu {
  max-height: 1000px;
}

#header .slider {
  color: #fff;
  max-width: 100vw;
  height: calc(100vh - 230px);
  display: flex;
  position: relative;
  overflow: hidden;
}

#header .slider .logo {
  width: 90px;
}

#header .slider .logo img {
  width: 100%;
}

#header .slider .slide--text {
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 0;
  font-family: Neo Sans Pro, sans-serif;
  transition: all 1s;
}

#header .slider .slide--storia {
  margin: 20px;
  padding: 0;
}

#header .slider .slide {
  z-index: 2;
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#header .slider .slide h1 {
  color: #fff;
  font-size: 2.5rem;
}

#header .slider .slide .btn--storia {
  margin-bottom: 20px;
}

#header .slider .slide .bg1 {
  z-index: -2;
  width: 100%;
  height: 100%;
  background: url("tribune_originale.388ccbf8.jpg") center / cover no-repeat;
  position: absolute;
}

#header .slider .slide .bg2 {
  z-index: -2;
  width: 100%;
  height: 100%;
  background: url("gabatel-marcolin-bg.e28406a6.png") center / cover no-repeat;
  position: absolute;
}

#header .slider .dots {
  z-index: 2;
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

#header .slider .dots__dot {
  color: #c9c8c8;
  opacity: .7;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 5px;
  transition: all .5s;
}

#header .slider .dots__dot:last-child {
  margin: 0;
}

#header .slider .dots__dot--active {
  color: #fff;
  opacity: 1;
}

#navbar-2 {
  height: 100px;
  background-color: #52b3de;
}

#navbar-2 .icons {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#navbar-2 .icons img {
  width: 30px;
}

#navbar-2 .icons .icon {
  width: 25%;
}

#navbar-2 .icons .icon--text {
  margin: 0;
  padding: 3px;
  font-size: .7rem;
}

main {
  margin-top: 40px;
}

#classifica {
  margin: auto;
}

#news, .news {
  max-width: 1400px;
  margin: 0 auto;
}

#news .news--h1, .news .news--h1 {
  grid-column: span 3;
  padding-top: 0;
}

#news .filter-button-group, .news .filter-button-group {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  display: flex;
}

#news .filter-button-group .filter-button, .news .filter-button-group .filter-button {
  width: 100%;
}

#news .filter-button-group .filter-button.active, .news .filter-button-group .filter-button.active {
  background-color: #fff;
  border: 2px solid #52b3de;
}

#news .news--container a, .news .news--container a {
  margin: 0 auto;
}

#news .news--content, .news .news--content {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#news .news--content img, .news .news--content img {
  width: 100%;
  aspect-ratio: 5 / 7;
  box-shadow: 10px 20px 15px #333;
}

#news .news--content .news--text, .news .news--content .news--text {
  width: 75%;
  text-align: left;
  background-color: #fff;
  flex-grow: 1;
  margin: 0 20px;
  padding: 10px 20px 0;
  position: relative;
  top: -50px;
  box-shadow: 4px 4px #52b3de;
}

#news .news--content .news--text .news--date, .news .news--content .news--text .news--date {
  color: gray;
  font-size: .8rem;
}

#news .splide__slide, .news .splide__slide {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 4%;
  transition: all .3s;
  display: flex;
}

#news .splide__slide:hover a img, .news .splide__slide:hover a img {
  filter: none;
}

#news .splide__slide:hover .news--text, .news .splide__slide:hover .news--text {
  background-color: #333;
  transition: all .3s ease-in-out;
}

#news .splide__slide:hover .news--text .news--date, #news .splide__slide:hover .news--text .news--title, .news .splide__slide:hover .news--text .news--date, .news .splide__slide:hover .news--text .news--title {
  color: #fff;
}

#news .splide__slide a, .news .splide__slide a {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

#news .splide__slide a img, .news .splide__slide a img {
  filter: saturate(70%);
}

#videos, .videos {
  text-align: center;
  justify-content: center;
}

#videos .video--container, .videos .video--container {
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
  display: flex;
}

#videos .video--container h1, .videos .video--container h1 {
  grid-column: span 3;
}

#videos .video--container .video--content, .videos .video--container .video--content {
  flex-direction: column;
  margin: auto;
  display: flex;
}

#videos .video--container .video--content video, .videos .video--container .video--content video {
  width: 100%;
  aspect-ratio: 16 / 12;
  margin: auto;
}

#videos .video--container .video--content .video--text, .videos .video--container .video--content .video--text {
  background-color: #fff;
  flex-grow: 1;
  line-height: 30px;
  box-shadow: 4px 4px #52b3de;
}

#videos .video--container .video--content .video--date, .videos .video--container .video--content .video--date {
  color: gray;
  text-align: left;
  margin: 10px;
  font-size: .8rem;
}

#videos .video--container .video--content .video--title, .videos .video--container .video--content .video--title {
  text-align: left;
  margin: 20px 10px;
  padding-bottom: 20px;
}

#sponsor {
  width: calc(100% - 4rem);
  max-width: 1400px;
  flex-direction: column;
  gap: 5%;
  margin: 0 auto;
  display: flex;
}

#sponsor .main-sponsor {
  width: 100%;
  border-bottom: 3px solid #52b3de;
  padding: 10px 0 20px;
}

#sponsor .main-sponsor h1 {
  text-align: center;
}

#sponsor .main-sponsor img {
  width: 100%;
}

#sponsor .official--title {
  padding: 10px;
}

#sponsor .other-sponsor {
  width: 100%;
  border-bottom: 3px solid #52b3de;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto 20px;
  padding: 10px;
  display: grid;
}

#sponsor .other-sponsor h1 {
  grid-column: span 3;
}

#sponsor .other-sponsor .sponsor-img {
  margin: 10px;
}

#sponsor .other-sponsor .sponsor-img img {
  width: 100%;
}

#footer {
  background: linear-gradient(#fff 10%, #52b3de);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

#footer a {
  transition: all .3s;
}

#footer a:hover {
  transform-origin: center;
  scale: 1.2;
}

#footer .social {
  width: 82px;
  color: #000;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

#footer .privacy-policy {
  align-items: center;
  margin-bottom: 10px;
  font-size: .6rem;
  display: flex;
}

#footer .privacy-policy .policy-title {
  margin: 0 10px;
}

#footer .preferenze-cookie {
  margin: 10px;
  font-size: .6rem;
}

#footer .last--row {
  text-align: center;
  font-size: .8rem;
}

#footer .last--row .developer i {
  padding: 0 .5rem;
}

body {
  background-color: #eee;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

* {
  -webkit-tap-highlight-color: #0000;
}

#prossima-partita, section + section:not(#classifica, #link-videos) {
  margin-top: 40px !important;
}

section {
  width: calc(100% - 4rem);
  margin: 0 auto;
}

.page--container > section {
  width: unset;
}

button {
  color: #000;
  cursor: pointer;
  background-color: #52b3de;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-family: Neo Sans Pro, sans-serif;
  font-size: 1rem;
  transition: all .3s;
}

button a {
  padding: 5px 15px;
  font-weight: bold;
}

button:hover {
  background-color: gray;
}

a {
  color: inherit;
  letter-spacing: .05em;
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
}

h1 {
  color: #52b3de;
  text-shadow: 1px 1px 2px #000;
  text-align: center;
  margin: 20px 10px 30px;
  font-size: 2rem;
  font-weight: bolder;
}

h3 {
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 0 15px;
  font-family: Varela Round, sans-serif;
  font-size: 1rem;
}

h4 {
  font-size: 1.2rem;
}

.buttons--container {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin: 0 auto;
  display: flex;
}

.return--link {
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
  background-color: #52b3de;
  border: 2px solid #52b3de;
  border-radius: 30px;
  margin: 0 auto;
  padding: 10px 20px;
  transition: all .3s;
}

.return--link a {
  font-weight: bold;
}

.return--link:hover {
  background-color: #fff;
}

.bar {
  color: gray;
  vertical-align: top;
  text-shadow: none;
  font-family: Neo Sans Pro, sans-serif;
  font-weight: 100;
}

@media (max-width: 413px) {
  .bar {
    display: none;
  }
}

.btn--plus {
  color: gray;
  text-shadow: none;
  vertical-align: middle;
  font-family: Neo Sans Pro, sans-serif;
  font-size: .8rem;
  font-weight: lighter;
}

@media (max-width: 413px) {
  .btn--plus {
    display: block;
  }
}

.section {
  transition: all 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(2rem);
}

#coppa-veneto {
  margin-top: 30px;
  padding-bottom: 0;
}

.squadre .arrow--down, .squadre .arrow--up {
  transition: all .5s ease-in-out;
}

.ul--squadre {
  max-height: 0;
  color: #fff;
  font-size: 1rem;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.ul--squadre li {
  padding: 10px;
}

.overlay {
  opacity: .5;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  position: absolute;
}

.hidden {
  opacity: 0;
}

.display--none {
  transition: all .5s ease-in-out;
  display: none;
}

iframe {
  width: 97%;
}

.iframe--classifica--prima-squadra {
  height: 680px;
}

.iframe--classifica--coppa {
  height: 4800px;
}

.iframe--classifica--juniores {
  height: 500px;
}

.iframe--classifica--allievi {
  height: 650px;
}

.iframe--classifica--giovanissimi {
  height: 680px;
}

.iframe--classifica--giovanissimi_2 {
  height: 460px;
}

.iframe--classifica--esordienti {
  height: 380px;
}

.iframe--marcatori, .iframe--marcatori-coppa {
  height: 700px;
}

.iframe--risultati {
  height: 520px;
}

.iframe--risultati-coppa {
  height: 400px;
}

.iframe--prossima-partita {
  height: 300px;
}

.page--container {
  width: calc(100% - 4rem);
  margin: 60px auto;
}

.page--container .single--container {
  width: 100%;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
}

.page--container .single--container img {
  width: 100%;
  box-shadow: 10px 20px 15px #333;
}

.page--container .single--container .single--news--content .single--news--text {
  text-align: left;
}

.page--container .single--container .single--news--content .single--news--text .single--news--date {
  color: gray;
  font-size: .8rem;
}

.page--container .single--container .single--news--content .single--news--article {
  line-height: 160%;
}

#storia {
  text-align: center;
  width: calc(100% - 4rem);
  justify-content: center;
  margin: 0 auto;
}

#storia img {
  width: 100%;
  padding-bottom: 30px;
}

#storia p {
  text-align: left;
  padding: 0 12px;
  line-height: 2;
}

.squadre--container {
  margin: auto;
}

.squadre--container img {
  width: 100%;
  margin: 20px 0;
}

#gallery {
  text-align: center;
  justify-content: center;
  align-items: center;
}

#gallery .gallery--container {
  flex-direction: column;
}

#gallery .gallery--container .photo {
  text-align: center;
  padding: 5px 0;
}

#gallery .gallery--container .photo p {
  margin: 10px;
  font-size: 1.3rem;
}

#gallery .gallery--container .photo img {
  width: 100%;
}

#gallery .btn--gallery {
  margin-top: 30px;
}

.single--photo--container {
  text-align: center;
}

.single--photo--container img {
  width: 100%;
}

#contatti {
  justify-content: center;
  align-items: center;
}

#contatti .ul--contatti {
  padding: 20px;
  display: inline;
}

#contatti .ul--contatti .contatti--span {
  padding: 5px 0;
  font-weight: bolder;
  display: block;
}

#contatti .ul--contatti li {
  padding: 10px;
}

#map {
  width: 100%;
  text-align: center;
}

#map .googleMapsIframe {
  width: 90%;
  height: 600px;
}

.splide__pagination {
  bottom: 0 !important;
}

.splide__pagination__page.is-active {
  background-color: #52b3de !important;
}

.splide__arrow--prev {
  left: -1em !important;
}

.splide__arrow--next {
  right: -1em !important;
}

.splide__arrow {
  height: 3em !important;
  width: 3em !important;
  background: none !important;
  top: 35% !important;
}

.splide__arrow svg {
  fill: #52b3de !important;
  height: 3em !important;
  width: 3em !important;
}

.maintain_logo {
  width: 200px;
  margin: 30px auto;
}

.maintain_logo img {
  width: 100%;
}

@media (min-width: 640px) and (max-height: 415px) {
  #navbar .logo img {
    scale: 1;
  }

  #navbar-2 {
    display: none;
  }

  #header .slider {
    height: 85vh;
  }

  #header .slider .slide .logo {
    width: 90px;
  }

  #header .slider .slide h1 {
    display: none;
  }

  #header .slider .slide h3 {
    padding: 0;
    scale: .6;
  }

  #header .slider .dots {
    top: 200px;
    transform: translateX(-50%)scale(.7);
  }

  #navbar-2 {
    height: 15vh;
  }

  #news .news--container, .news .news--container {
    grid-gap: 20px;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    display: grid;
  }

  #news .news--container .news--content img, .news .news--container .news--content img {
    max-width: 200px;
    max-height: 250px;
  }

  #news .news--container .news--content .news--text, .news .news--container .news--content .news--text {
    scale: .7;
  }

  #news .news--container .news--content .news--text .news--title, .news .news--container .news--content .news--text .news--title {
    font-size: 1rem;
  }

  #videos .video--container, .videos .video--container {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    display: grid;
  }

  #videos .video--content, .videos .video--content {
    height: 400px;
  }

  #videos .video--text, .videos .video--text {
    min-height: 120px;
  }

  #videos .video--text .video--title, .videos .video--text .video--title {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (min-height: 416px) {
  #prossima-partita, #classifica {
    text-align: center;
    margin: auto;
  }

  #sponsor .other-sponsor {
    grid-template-columns: repeat(3, 1fr);
  }

  .page--container {
    max-width: 1400px;
  }

  .page--container .buttons--container {
    flex-direction: row;
  }

  .page--container .single--container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }

  .page--container .single--container img {
    width: 30%;
    min-width: 300px;
  }

  .page--container .single--container .single--news--content .single--news--article {
    font-size: 1.2rem;
  }

  #gallery .gallery--container {
    grid-gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    align-items: baseline;
    padding: 20px;
    display: grid;
  }

  .single--photo--container {
    width: 80%;
    text-align: center;
    margin: auto;
  }
}

@media (min-width: 900px) {
  #prossima-partita, section + section:not(#link-videos) {
    margin-top: 60px !important;
  }

  #header .slide h1 {
    font-size: 3rem;
  }

  main {
    margin-top: 80px;
  }

  #sponsor {
    flex-direction: row;
    justify-content: space-between;
  }

  #sponsor .main-sponsor {
    width: 40%;
    border: none;
  }

  #sponsor .other-sponsor {
    width: 40%;
    border: none;
    margin-left: auto;
  }

  #storia p {
    column-count: 2;
    column-gap: 80px;
  }

  .news .box .news--content:hover a img {
    filter: none;
  }

  .news .box .news--content:hover .news--text {
    background-color: #333;
    transition: all .3s ease-in-out;
  }

  .news .box .news--content:hover .news--text .news--date, .news .box .news--content:hover .news--text .news--title {
    color: #fff;
  }
}

@media (min-width: 1024px) {
  iframe {
    width: 100%;
  }

  #navbar {
    padding: 0 130px 0 80px;
  }

  #navbar > div {
    width: 100%;
  }

  #navbar .menu-wrap {
    display: none;
  }

  #navbar .menu--inline {
    display: flex;
  }

  h1 {
    text-align: center;
    padding: 30px 0;
    font-size: 3rem;
  }

  h3 {
    text-align: center;
    padding-bottom: 30px;
  }

  .squadre--container {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .squadre--container img, .squadre--container iframe {
    width: 80%;
  }

  .squadre--container .category--title {
    margin: 0;
  }

  #header .slider {
    height: calc(100vh - 130px);
  }

  #header .slider .logo {
    width: 120px;
  }

  #header .slider .slide h1 {
    color: #fff;
    font-size: 5rem;
  }

  #navbar-2 {
    display: none;
  }

  #news .news--container, .news .news--container {
    text-align: center;
  }

  #news .news--container .filter-button-group, .news .news--container .filter-button-group {
    margin-bottom: 80px;
  }

  #news .news--container .filter-button-group .filter-button, .news .news--container .filter-button-group .filter-button {
    width: auto;
  }

  #news .news--container a, .news .news--container a {
    width: 33%;
  }

  #news .news--container .news--content img, .news .news--container .news--content img {
    max-width: 80%;
    aspect-ratio: 16 / 22;
  }

  #news .news--container .news--content .news--text, .news .news--container .news--content .news--text {
    width: 60%;
    margin: 0 auto;
  }

  #videos .video--container, .videos .video--container {
    width: calc(100% - 8rem);
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    margin: 0 auto;
    display: grid;
  }

  #videos .video--content, .videos .video--content {
    height: 400px;
  }

  #videos .video--text, .videos .video--text {
    min-height: 120px;
  }

  #storia {
    width: calc(100% - 8rem);
  }

  #storia img, #storia p {
    max-width: 1000px;
    margin: 0 auto;
  }

  #storia p {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  #contatti {
    text-align: center;
  }

  #sponsor .main-sponsor {
    margin-bottom: 60px;
  }
}

/*# sourceMappingURL=index.a65da878.css.map */
