@import "utilityes";

@media (min-width: 640px) and (max-height: 415px) {
  /* iphone in landscape */
  #navbar {
    .logo {
      img {
        scale: 1;
      }
    }
  }
  #navbar-2 {
    display: none;
  }
  #header {
    .slider {
      height: 85vh;

      .slide {
        .logo {
          width: 90px;
        }

        h1 {
          display: none;
        }

        h3 {
          scale: 0.6;
          padding: 0;
        }
      }

      .dots {
        top: 200px;
        transform: translateX(-50%) scale(0.7);
      }
    }
  }
  #navbar-2 {
    height: 15vh;
  }
  #news,
  .news {
    .news--container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      align-items: center;
      justify-content: center;
      text-align: center;

      .news--content {
        img {
          max-width: 200px;
          max-height: 250px;
        }

        .news--text {
          scale: 0.7;

          .news--title {
            font-size: 1rem;
          }
        }
      }
    }
  }

  #videos,
  .videos {
    .video--container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      align-items: flex-start;
    }

    .video--content {
      height: 400px;
    }

    .video--text {
      min-height: 120px;

      .video--title {
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: 768px) and (min-height: 416px) {
  /* iPad e Tablet più recenti */
  #prossima-partita,
  #classifica {
    margin: auto;
    text-align: center;
  }
  #sponsor {
    .other-sponsor {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  // Pagina single_news
  .page--container {
    max-width: 1400px;

    .buttons--container {
      flex-direction: row;
    }

    .single--container {
      flex-direction: row;
      gap: 100px;
      align-items: center;
      justify-content: center;

      img {
        width: 30%;
        min-width: 300px;
      }

      .single--news--content {
        .single--news--article {
          font-size: 1.2rem;
        }
      }
    }
  }
  // Gallery
  #gallery {
    .gallery--container {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px;
      align-items: baseline;
    }
  }
  .single--photo--container {
    width: 80%;
    margin: auto;
    text-align: center;
  }
}

/* TABLET LANDSCAPE, LAPTOPS, DESKTOPS */

@media (min-width: 900px) {
  #prossima-partita,
  section + section:not(#link-videos) {
    margin-top: 60px !important;
  }

  #header {
    .slide {
      h1 {
        font-size: 3rem;
      }
    }
  }

  main {
    margin-top: 80px;
  }

  #sponsor {
    flex-direction: row;
    justify-content: space-between;

    .main-sponsor {
      border: none;
      width: 40%;
    }

    .other-sponsor {
      border: none;
      width: 40%;
      margin-left: auto;
    }
  }

  #storia p {
    column-count: 2;
    column-gap: 80px;
  }

  .news {
    .box {
      .news--content {
        &:hover {
          a img {
            filter: none;
          }

          .news--text {
            background-color: $hoverColor;
            @include transition();

            .news--date,
            .news--title {
              color: $light-color;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  /* Desktop e Laptop */

  iframe {
    width: 100%;
  }

  #navbar {
    padding: 0 130px 0 80px;

    > div {
      width: 100%;
    }

    .menu-wrap {
      display: none;
    }

    .menu--inline {
      display: flex;
    }
  }

  h1 {
    font-size: 3rem;
    text-align: center;
    padding: 30px 0;
  }
  h3 {
    text-align: center;
    padding-bottom: 30px;
  }
  .squadre--container {
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
    }

    iframe {
      width: 80%;
    }

    .category--title {
      margin: 0;
    }
  }
  #header {
    .slider {
      height: $sliderHeight;

      .logo {
        width: 120px;
      }

      .slide {
        h1 {
          font-size: 5rem;
          color: $light-color;
        }
      }
    }
  }
  #navbar-2 {
    display: none;
  }

  #news,
  .news {
    .news--container {
      text-align: center;

      .filter-button-group {
        margin-bottom: 80px;
        .filter-button {
          width: auto;
        }
      }

      a {
        width: 33%;
      }

      .news--content {
        img {
          max-width: 80%;
          aspect-ratio: 16/22;
        }

        .news--text {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
  }

  #videos,
  .videos {
    .video--container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: flex-start;
      width: calc(100% - 8rem);
      margin: 0 auto;
    }

    .video--content {
      height: 400px;
    }

    .video--text {
      min-height: 120px;
    }
  }

  #storia {
    width: calc(100% - 8rem);

    img,
    p {
      max-width: 1000px;
      margin: 0 auto;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.8;
    }
  }
  #contatti {
    text-align: center;
  }

  #sponsor {
    .main-sponsor {
      margin-bottom: 60px;
    }
  }
}

// large screen
@media (min-width: 1400px) {
  //#navbar {
  //  width: $sectionWidth;
  //}
}
